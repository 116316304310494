<!--学习园地：我的课程-->
<template>
  <div id="MyCourse" class="page-box">
    <el-tabs v-model="activeName" @tab-click="handleChange">
      <el-tab-pane label="学习中" name="first">
        <NoData v-if="freeCouseNoData" />
        <el-row v-else :gutter="30">
          <el-col :span="12" v-for="item in study" :key="item.courseUserId">
            <div class="main-box">
              <!--图片栏-->
              <div class="img">
                <!--图片-->
                <img :src="item.courseCover" alt="" style="cursor: pointer;" @click="ToCourseDetails(item.courseId)" />
                <!--按钮-->
                <el-button v-if="item.idFree" type="text" @click="cancel(item.courseUserId)">
                  取消报名
                </el-button>
              </div>
              <!--内容栏-->
              <div class="content">
                <!--标题-->
                <div class="title">
                  {{ item.courseName }}
                </div>
                <!--学习进度、学习章节名称-->
                <div class="text">
                  <div class="left">
                    已学习
                    <span class="number">{{ item.finish }}</span>
                    <span class="unit">%</span>
                  </div>

                  <div v-if="item.chapterName" class="right">学习至：{{ item.chapterName }}</div>
                </div>
                <!--按钮-->
                <div class="btn-group">
                  <el-button
                    type="primary"
                    plain
                    @click="ToPlayer(item.courseUserId, item.courseId)"
                  >
                    进入学习
                  </el-button>
                  <el-button
                    v-if="item.homework === 1"
                    type="primary"
                    plain
                    @click="Goto(item.courseUserId)"
                  >
                    课程作业
                  </el-button>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane label="已学完" name="second">
        <NoData v-if="chargeCourseNoData" />
        <el-row v-else :gutter="30">
          <el-col :span="12" v-for="item in finish" :key="item.courseUserId">
            <div class="main-box">
              <!--图片栏-->
              <div class="img">
                <!--图片-->
                <img :src="item.courseCover" alt="" style="cursor: pointer;" @click="ToCourseDetails(item.courseId)" />
                <!--按钮-->
                <el-button v-if="item.idFree" type="text" @click="cancel(item.courseUserId)">
                  取消报名
                </el-button>
              </div>
              <!--内容栏-->
              <div class="content">
                <!--标题-->
                <div class="title">
                  {{ item.courseName }}
                </div>
                <!--学习进度、学习章节名称-->
                <div class="text">
                  <div class="left">
                    已学习
                    <span class="number">{{ item.finish }}</span>
                    <span class="unit">%</span>
                  </div>

                  <div class="right">学习至：{{ item.chapterName }}</div>
                </div>
                <!--按钮-->
                <div class="btn-group">
                  <el-button
                    type="primary"
                    plain
                    @click="ToPlayer(item.courseUserId, item.courseId)"
                  >
                    进入学习
                  </el-button>

                  <el-button
                    v-if="item.homework === 1"
                    type="primary"
                    plain
                    @click="Goto(item.courseUserId)"
                  >
                    课程作业
                  </el-button>

                  <el-button
                    v-show="item.test == 1"
                    type="primary"
                    plain
                    @click="ToTest(item.courseId, item.courseUserId)"
                  >
                    开始考试
                  </el-button>
                  <el-button
                    v-show="item.test === 2"
                    type="primary"
                    plain
                    @click="ToTest(item.courseId, item.courseUserId)"
                  >
                    再次考试
                  </el-button>

                  <el-button
                    v-show="item.test === 3"
                    type="primary"
                    plain
                    @click="ToGrade(item.courseUserId, item.courseId)"
                  >
                    成绩查看
                  </el-button>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import API from '@/api';
import NoData from '@/components/NoData'; // 暂无数据组件
import cookies from '@/utils/cookies';

export default {
  components: { NoData },
  name: 'MyCourse',
  data() {
    return {
      activeName: 'first',
      token: '',
      study: [],
      finish: [],
      freeCouseNoData: false,
      chargeCourseNoData: false, // // 优质精选，是否展示无数据图片
    };
  },
  created() {
    // 获取学习中的课程
    this.studingCourse();
  },
  methods: {
    handleChange() {
      if (this.activeName === 'first') {
        this.studingCourse();
      } else {
        this.finishedCourse();
      }
    },
    // 查看课程详情页
    ToCourseDetails(id) {
      window.open(`${process.env.VUE_APP_PAY}?id=${id}`);
    },
    // 跳转到作业页面
    Goto(id) {
      this.$router.push({
        path: '/Course/MyCourse/Task',
        query: {
          id,
        },
      });
    },
    // 去成绩页面
    ToGrade(courseUserId, courseId) {
      this.$router.push({
        path: '/Course/MyCourse/Grade',
        query: {
          id: courseUserId,
          courseId,
        },
      });
    },
    // 去视频页面
    ToPlayer(courseUserId, courseId) {
      window.open(`${process.env.VUE_APP_USERCONSOLE}Player?courseUserId=${courseUserId}&courseId=${courseId}`);
    },
    // 去考试页面
    ToTest(courseId, courseUserId) {
      this.$router.push({
        path: '/Course/MyCourse/Test',
        query: {
          courseId,
          courseUserId,
        },
      });
    },
    // 获取学习中的课程
    studingCourse() {
      this.token = cookies.get('token');
      API.studyCourse(this.token)
        .then((res) => {
          // console.log('学习中', res)
          this.study = res.message.data;
          if (this.study.length === 0) {
            this.freeCouseNoData = true;
          }
        })
        .catch((mgs) => {
          this.freeCouseNoData = true;
          console.log(mgs);
        });
    },
    // 获取已学完的课程
    finishedCourse() {
      this.token = cookies.get('token');
      API.finishCourse()
        .then((res) => {
          // console.log('已学完', res)
          this.finish = res.message.data;
          if (this.finish.length === 0) {
            this.chargeCourseNoData = true;
          }
        })
        .catch((mgs) => {
          this.chargeCourseNoData = true;
          console.log(mgs);
        });
    },
    // 取消报名
    cancel(courseUserId) {
      this.$confirm('确定要取消报名吗？', '确定取消', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          API.cancelCourse({
            courseUserId,
          }).then((res) => {
            if (res.message.success === true) {
              this.$message({
                message: res.message.message,
                type: 'success',
              });
              this.studingCourse(); // 获取学习中的课程
              this.finishedCourse(); // 获取已学完的课程
            }
          });
        });
      // .catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '已取消报名',
      //   });
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
  .page-box {
    height: 100%;
    // tab页
    ::v-deep .el-tabs {
      height: 100%;
    }
    // tab页-内容
    ::v-deep .el-tabs__content {
      height: calc(100% - 55px);
      overflow: auto;
      margin-right: -40px;
    }
    ::v-deep .el-tab-pane {
      width: 1630px;
      margin: 0px auto;
      padding-right: 40px;
    }
  }
.main-box {
  display: flex;
  width: 780px;
  /*width: 100%;*/
  height: 195px;
  background: #fff;
  border-radius: 4px;
  margin: 0 0 30px 0;
  overflow: hidden;
  padding: 10px;
  box-shadow: 0 0 8px 1px rgba(215, 215, 215, 0.3);

  // 图片栏
  .img {
    flex: 0 0 auto;
    width: 310px;
    height: 100%;
    background: #e3e3e3;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
    // 图片
    img {
      width: 100%;
      height: 100%;
    }
    // 取消按钮
    .el-button {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      color: #fff;
      font-size: 16px;
      background: #00000066;
      padding: 7px 8px;
      border-radius: 2px;

      &:hover {
        color: #f3a000;
      }
    }
  }
  // 内容区域
  .content {
    flex: 1 1 auto;
    height: 100%;
    padding: 10px 10px 10px 15px;
    overflow: hidden;
    position: relative;

    // 标题
    .title {
      font-size: 14px;
      line-height: 24px;
      height: 48px;
      overflow: hidden;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 16px;
    }
    //金额、学习人数
    .text {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      white-space: nowrap;

      // 左侧文本
      .left {
        flex: 0 0 auto;
        width: 40%;
        font-size: 14px;
        font-weight: bold;
        color: #f3a000;
        overflow: hidden;
        text-overflow: ellipsis;

        // 百分比
        .number {
          font-size: 20px;
        }
        // 单位
        .unit {
          font-size: 16px;
        }
      }

      // 右侧文本
      .right {
        flex: 1 1 auto;
        width: 60%;
        font-size: 14px;
        color: #666;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: right;
      }
    }
    // 按钮组
    .btn-group {
      position: absolute;
      right: 10px;
      bottom: 10px;
      text-align: right;

      button {
        font-size: 14px;
        font-weight: bold;
        border-width: 2px;
        padding: 10px 23px;
        + button {
          margin-left: 20px;
        }
      }
    }
  }
}
</style>
